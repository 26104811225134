/*header/section*/

h1,
h2,
h3,
h4,
h5,
h6,
header,
section {
  font-family: verdana;
}

/*style*/

.card.hover-shadow-light,
.hover-shadow-light {
  transition: all 0.5s ease-in-out;
}

.card.hover-shadow-light:hover,
.hover-shadow-light:hover {
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.5s ease-in-out;
}

.nav-pills .nav-link.active,
.nav-pills .nav-item.show .nav-link {
  background-color: #78a74f;
}

.basic-padding {
  padding-left: 20px;
  padding-right: 20px;
}

.basic-padding-top {
  padding-top: 50px;
}

.small-padding-top {
  padding-top: 15px;
}

.smallest-padding-top {
  padding-top: 7.5px;
}

.basic-padding-bottom {
  padding-bottom: 20px;
}

.basic-margin {
  margin-left: 23px;
  margin-bottom: 10px;
}

.basic-margin-bottom {
  margin-bottom: 20px;
}

.basic-margin-top {
  margin-top: 70px;
}

.width-fixed {
  width: 300px;
}

.green-font {
  color: green;
}

.bg-green {
  background-color: green;
}

.bg-blue {
  background-color: lightskyblue;
}

/*element changes*/

.sidenav-width-border {
  width: 250px;
  border: 1px solid #000;
}

.pos-nav-toggle {
  margin-left: 45px;
  margin-bottom: 10px;
}

.pos-nav-toggle-2 {
  margin-top: 10px;
  margin-left: 10px;
}

.invis-bullet-compensation {
  position: relative;
  right: 15px;
}

.admin-btn-margin {
  margin-left: 15px;
  margin-right: 15px;
}

.admin-logo {
  float: left;
  padding-left: 5px;
  padding-right: 10px;
}

.toggle-nav-box {
  width: 100%;
  height: 70px;
  border: 1px solid #000;
  background-color: lightblue;
}

.hide-toggle-nav {
  display: none;
}

/*media queries*/

@media screen and (max-width: 991px) {
  .hide-toggle-nav {
    display: block;
  }
}
